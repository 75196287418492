//=require ../../../node_modules/jquery/dist/jquery.min.js
//=require ../../../node_modules/bootstrap-sass/assets/javascripts/bootstrap.min.js
//=require ../../../node_modules/jquery-mask-plugin/dist/jquery.mask.min.js

jQuery(window).load(function() {

    "use strict";

    // Page Preloader
    jQuery('#preloader').delay(350).fadeOut(function() {
        jQuery('body').delay(350).css({ 'overflow': 'visible' });
    });
});

$(document).ready(function() {

    $('.telefone').mask('(00)00000-0000');

    /****************************** FUNCAO TESTA EMAIL **********************/

    function IsEmail(email) {
        var exclude = /[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
        var check = /@[\w\-]+\./;
        var checkend = /\.[a-zA-Z]{2,3}$/;
        if (((email.search(exclude) != -1) || (email.search(check)) == -1) || (email.search(checkend) == -1)) {
            return false;
        } else {
            return true;
        }
    }

    /****************************** ENVIA FORM CONTATO **********************/

    $("#formDesconto").submit(function (e) {
        e.preventDefault();

        $('#btnGeraDesconto').html('<i class="fa fa-spinner fa-spin"></i> Aguarde. Enviando.');

        var nome = $("#nome").val();
        var email = $("#email").val();
        var telefone = $("#telefone").val();
        var erros = 0;

        if (nome == '') {
            $("#nome").addClass('errorField');
            erros++;
        } else {
            $("#nome").removeClass('errorField');
        }
        if (email == '' || !IsEmail(email)) {
            $("#email").addClass('errorField');
            erros++;
        } else {
            $("#email").removeClass('errorField');
        }
        if (telefone == '') {
            $("#telefone").addClass('errorField');
            erros++;
        } else {
            $("#telefone").removeClass('errorField');
        }
        
        if (erros == 0) {

            $('#nomeCap').val(nome);
            $('#emailCap').val(email);
            $('#telCap').val(telefone);
            $('#urlRetorno').val(url + '/obrigado.html?nome='+ nome);

            $.post(url +'/form_contato.php', $("#formDesconto").serialize(), function (data) {
                if (data == '1') {
                    $("#formIagente").submit();
                    $(".erroContato").fadeOut('slow');
                    $("#formDesconto")[0].reset();
                    $(".sucessoContato").fadeIn('slow');
                    $('#btnGeraDesconto').html('Enviar');
                }
            });
        } else {
            $(".erroContato").fadeIn('slow');
            $('#btnGeraDesconto').html('Enviar');
        }
    });

});